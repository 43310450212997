

*{
  font-family: 'Inknut Antiqua';
}

body {
  background-color: #f0f8ff;
}

/* Navigation */
nav {
  margin: 30px 0 135px 0;
  text-align: center
}

nav ul li {
  display: inline-block;
  margin-right: 50px;
}
nav ul li a span {
  color: #000
}

.button {
  display: inline-block;
  height: 80px;
  width: 80px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  transition: all .3s ease-out;
  overflow: hidden
}


.button:hover {
  width: 200px;
  background-color: #82ECC0
}

.button .icon {
  display: inline-block;
  height: 80px;
  width: 80px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 80px
}

.button .icon i {
  font-size: 25px;
  line-height: 60px;
  color: #000
}
#menu {
  display: none;
  color: #000;
  text-align: right
}

#menulist, .row, .col-7, .col-5{
  margin: 0;
  padding: 0;
}


/* Home page */
.maintext{
  margin: 155px 0 156px 250px;
}
.text1{
  font-size: 30px;

}
.text2{
  font-size: 80px;
}

/* Footer */
footer{
  text-align: center;
  margin: 50px 0 50px 0;
}
footer p{
  font-size: 18px;
  font-weight: 400;
}

/* About */
.about p{
  margin: 20px 246px 0 0;
}
.about h1{
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.about img{
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 300px;
}
.about, .contact{
  margin: 100px 0 100px 0;
}

/* Contact */
.contact .First{
  margin: 110px 55px 100px 245px;
}
.contact .txt1{
  font-size: 20px;
  font-weight: 600;
}
.contact .txt2{
  font-size: 80px;
  font-weight: 600;
}
.contact .txt3{
  font-size: 15px;
  font-weight: 500;
}

.contactlinks a i {
  padding: 10px 20px 10px 0;
  color: #000
}

.contactlinks a i:hover {
  color: #0563bb
}

#Fullname, #email, #message
{
  display: block;
}

#form{
  background-color: #D0E2F2;
  padding: 30px 20px 20px 20px;
  border-radius: 20px;
  margin-right: 249px;
}
#form h1{
  font-size: 20px;
  font-weight: 600;
}
.form{
  margin: 15px 0 0 15px;
}
.fullname, .email, .message{
  display: block;
  width: 80%;
  height: 40px;
  border: none;
  border-radius: 15px;
}

.message{
  height: 80px;
}

.submit{
  background: #198724;
  border: none;
  border-radius: 20px;
  color: #fff;
  padding: 10px 30px 10px 30px;
}
.form label{
  margin: 10px 0 10px 0;
}

/* Experience */
.exp{
  margin: 0 150px 0 150px;
}


.experience{
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 485px;
  text-align: center;
}
.experience p{
  margin: 0 50px 0 50px;
  text-align: left;
}

.box{
  height: 250px;
  background-color: #82ECC0;
  background-color: #D0E2F2;
  margin-top: -200px;
}

.experience a{
  color: #000;
  font-weight: 600;
  text-decoration: none;
}
.experience a:hover{
  color: #5d5c5c;
}

/* Ipad */

  /* Home page */
  @media (max-width: 1024px) {
    .maintext{
      margin: 113px 0 112px 120px;
    }
    .banner{
      width: 300px;
    }
    .text1{
      font-size:18px;
      }
    .text2{
        font-size:40px;
      }
        /* Experience */
    .exp{
      margin: 0;
    }
    
    .experience p{
      margin: 0;
      text-align: left;
    }
  
  
  }
  
  /* About page */
  @media (max-width: 1024px) {
    .about p, .about h1{
      margin: 20px 0 20px 0;
      text-align: justify;
    }
    .about p{
      font-size: small;
    }
    .about h1{
      font-size: 18px;
    }
    .about{
      margin: 0 20px 0 20px;
    }
    .about img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 70%;
  }
  }
  
  /* Contact page */
  @media (max-width: 1024px) {
    .contact .First{
      margin: 125px 70px 0 100px;
    }
    .contact .txt1{
      font-size: 20px;
      font-weight: 600;
      line-height: 35px;
    }
    .contact .txt2{
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
    }
    .contact .txt3{
      font-size: 16px;
      font-weight: 500;
      line-height: 35px;
    }
  
    .contactlinks a i {
      color: #000;
    }
    #form{
      background-color: #D0E2F2;
      padding: 30px 20px 20px 20px;
      border-radius: 20px;
    }
    #form{
      margin: 20px 35px 0 35px  
    }
    #form h1{
      font-size: 16px;
      font-weight: 600;
    }
    .form{
      margin: 15px 0 0 15px;
    }
    .fullname, .email, .message{
      display: block;
      width: 90%;
      height: 35px;
      border: none;
      border-radius: 15px;
    }
    
    .message{
      height: 80px;
    }
    
    .submit{
      background: #198724;
      border: none;
      border-radius: 20px;
      color: #fff;
      padding: 10px 30px 10px 30px;
    }
    .form label{
      margin: 5px 0 5px 0;
      font-size: 13px;
    }
    
  }

/* Mobile Phone */
/* Home page */
@media (max-width: 670px) {
  .maintext{
    margin: 45px 0 10px 50px;
  }
  .banner{
    width: 155px;
  }
  .text1{
    font-size:18px;
    margin: 0;
    }
  .text2{
      font-size:35px;
      margin: 0;
    }
    footer p{
      font-size: small;
      margin: 0;
    }
    footer{
      margin: 20px 0 0 0;
    }
    .contactlinks{
      font-size: 10px;
    }

    .contactlinks a i{
      padding: 10px 10px 10px 0;
    }

}

/* About page */
@media (max-width: 670px) {
  .about p, .about h1{
    margin: 20px 0 20px 0;
    text-align: justify;
  }
  .about p{
    font-size: small;
  }
  .about h1{
    font-size: 18px;
  }
  .about{
    margin: 0 20px 0 20px;
  }
  .about img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
}
}

/* Contact page */
@media (max-width: 670px) {
  .contact{
    margin: 0;
  }
  .contact .First{
    margin: 0 10% 0 20%;
  }
  .contact .txt1{
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
  }
  .contact .txt2{
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
  }
  .contact .txt3{
    font-size: 13px;
    font-weight: 500;
    line-height: 35px;
  }

  .contactlinks a i {
    color: #000
  }
  #form{
    background-color: #D0E2F2;
    padding: 30px 20px 20px 20px;
    border-radius: 20px;
  }
  #form{
    margin: 20px 35px 0 35px  
  }
  #form h1{
    font-size: 16px;
    font-weight: 600;
  }
  .form{
    margin: 15px 0 0 15px;
  }
  .fullname, .email, .message{
    display: block;
    width: 90%;
    height: 35px;
    border: none;
    border-radius: 15px;
  }
  
  .message{
    height: 80px;
  }
  
  .submit{
    background: #198724;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 10px 30px 10px 30px;
  }
  .form label{
    margin: 5px 0 5px 0;
    font-size: 13px;
  }
  
}

/* Nav */
@media (max-width: 670px) {
  nav{
    margin: 20px;
  }
nav ul li {
        display:block;
        text-align: left
    }

    nav ul li a {
        text-decoration: none
    }

    #menu {
        display: block
    }

    #menulist {
        overflow: hidden;
        transition: .5s
    }

    .button {
        display: inline;
        padding: 15px;
        /* margin: 0 20px; */
        border-radius: 50px;
        overflow: visible
    }

    .button:hover {
        background-color: #82ECC0
    }

    .button .icon {
        display: inline-block;
        height: 60px;
        width: 60px;
        text-align: center;
        border-radius: 50px;
        box-sizing: border-box;
        line-height: 60px
    }
    .fa-ellipsis-v:before{
      font-size: 25px;
    }
/* Experience */
.exp{
  margin: 0 100px 0 100px;
}

.experience p{
  margin: 0;
  text-align: left;
}


  }

/* laptop screen */

@media (max-width: 1450px) {
/* Experience */
.exp{
  margin: 0 100px 0 100px;
}

.experience p{
  margin: 0;
  text-align: left;
}
}

@media (max-width: 1025px) {
/* Experience */
.exp{
margin: 0 50px 0 50px;
}

.experience p{
margin: 0;
text-align: left;
}
}

@media (max-width: 769px) {
  /* Experience */
.exp{
margin: 0 20px 0 20px;
}
.experience{
  margin-top: 50px;
  height: fit-content;
}
}




